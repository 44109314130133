<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <validation-provider name="استان" rules="required" v-slot="{ errors }">
          <Multiselect
              v-model="selectedProvince"
              :searchable="true"
              :options="states"
              @input="selectState"
              placeholder="انتخاب استان"
              label="name"
              select-label=""
              selected-label="انتخاب شده"
              deselectLabel=""
              open-direction="bottom"
              track-by="name"><span slot="noResult">نتیجه ای یافت نشد</span></Multiselect>
          <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>

        </validation-provider>
        <validation-provider name="شهر" rules="required" v-slot="{ errors }">
          <Multiselect
              class="mt-3"
              v-model="selectedCity"
              @input="selecteCity"
              :searchable="true"
              :options="cities"
              placeholder="انتخاب شهر"
              label="name"
              select-label=""
              selected-label=""
              deselectLabel=""
              open-direction="bottom"
              track-by="name"><span slot="noResult">نتیجه ای یافت نشد</span> <span
              slot="noOptions">استان را انتخاب کنید</span></Multiselect>
          <span class="text-sm text-red-500 text-right inline-block ">{{ errors[0] }}</span>

        </validation-provider>
        <div v-if="selectedCity !== null " style="width: 100%; height: 300px;">
          <mapir
              :apiKey="mapirToken"
              :mapStyle="options.style"
              :center="coordinates"
              :zoom="11"
              @click="moveMap"
          >
            <mapMarker
                :coordinates="markerCoordinates"
                color="blue"
                :draggable="true"
                @dragend="getlatLng"
            ><template slot="marker">
              <img class="w-16 h-16 rounded-full" src="../../assets/images/icons/location-pin.svg" alt="">
            </template>
            </mapMarker>
          </mapir>
          <a class="text-xs text-white mt-5 cursor-pointer" @click="getNowLocation">ادرس من </a>
        </div>
        <validation-provider name="آدرس" rules="required|min:6" v-slot="{ errors }">
          <label class="block relative mt-7">
            <span class="text-white opacity-50 text-sm">آدرس</span>
            <input
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="address"
            >
            <span class="absolute top-8">
          </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>
        </validation-provider>
        <validation-provider name="محله" rules="required|min:3" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">محله</span>
            <input
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="area"
            >
            <span class="absolute top-8">
          </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>
        </validation-provider>
        <label class="block relative mt-7">
          <span class="text-white opacity-50 text-sm">کد معرف</span>
          <input
              type="text"
              class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
              v-model="token"
          >
          <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="9.864" viewBox="0 0 15.5 9.864">
              <path id="ic_group_24px"
                    d="M11.568,9.227A2.114,2.114,0,1,0,9.455,7.114,2.1,2.1,0,0,0,11.568,9.227Zm-5.636,0A2.114,2.114,0,1,0,3.818,7.114,2.1,2.1,0,0,0,5.932,9.227Zm0,1.409C4.29,10.636,1,11.461,1,13.1v1.761h9.864V13.1C10.864,11.461,7.573,10.636,5.932,10.636Zm5.636,0c-.2,0-.437.014-.683.035A2.973,2.973,0,0,1,12.273,13.1v1.761H16.5V13.1C16.5,11.461,13.21,10.636,11.568,10.636Z"
                    transform="translate(-1 -5)" fill="#ff7400"/>
          </svg>
        </span>
          <span class="text-sm text-red-500 text-right inline-block" v-if="errorText">{{ errorText }}</span>
        </label>
        <button type="submit" class="btn-custom mt-11 w-full">بعدی
          <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </button>
      </form>
    </ValidationObserver>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from "../../plugins/axios";
import mapAxios from "../../plugins/mapAxios";
import {mapir, mapMarker} from "mapir-vue";
import {mapState} from 'vuex'
export default {
  name: "signup3",
  components: {
    Multiselect,
    mapir,
    mapMarker
  },
  data() {
    return {
      selectedProvince: null,
      states: [],
      selectedCity: null,
      cities: [],
      token: '',
      area : '',
      address : '',
      ostanha: null,
      shahrha: null,
      errorText: null,
      coordinates: [50.836656, 28.967274],
      markerCoordinates: [50.836656, 28.967274],
    }
  },
  methods: {
    selectState(select) {
      this.selectedCity = null;
      this.ostanha.forEach(item => {
        if (select.id === item.id) {
          this.cities = item.cities
        }
      })
    },
    selecteCity(){
      this.coordinates = [ this.selectedCity.lng ,this.selectedCity.lat ]
      this.markerCoordinates = [ this.selectedCity.lng ,this.selectedCity.lat ]
    },
    onSubmit() {
      if (this.token != '') {
        axios.post('validatation/invite', {
          value: this.token
        }).then(({data}) => {
          if (data.status) {
            this.dispatchData()
          } else {
            this.errorText = 'کد معرف انتخاب شده معتبر نمی باشد'
            return false;
          }
        }).catch((err) => {
          this.errorText = err.message
        })
      } else {
        this.dispatchData()
      }
    },
    dispatchData() {
      const allData = {
        step : 4,
        province: this.selectedProvince.name,
        city: this.selectedCity.name,
        areaName: this.area,
        address: this.address,
        invite_token : this.token,
        geoPosition: {
          latitude: this.markerCoordinates[1],
          longitude: this.markerCoordinates[0]
        }
      }
      this.$store.commit('auth/SET_SIGNUP_STEP3', allData)
    },
    getlatLng({component, map, marker}) {
      this.markerCoordinates = [marker['_lngLat'].lat,marker['_lngLat'].lng]
    },
    getNowLocation() {
      navigator.geolocation.getCurrentPosition(this.success, this.error)
    },
    success(pos) {
      var crd = pos.coords;
      this.markerCoordinates = [crd.longitude,crd.latitude ];
      this.coordinates = [crd.longitude,crd.latitude];
    },
    error() {
     this.$toast.error('دسترسی لوکشین را فعال کنید')
    },
    moveMap({actualEvent}) {
      var lngLat = actualEvent.lngLat;
      this.markerCoordinates = [lngLat.lng , lngLat.lat ]

    }
  },
  computed: {
    ...mapState({
      mapirToken : state => state.map.token,
      options : state => state.map.options
    })
  },
  mounted() {
    axios.post('/options/provinces?cities=1')
        .then(({data}) => {
          this.ostanha = data.data
          this.ostanha.forEach((item) => {
            this.states.push({id: item.id, name: item.name})
          })
        })
  },
  watch: {}
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect >>> .multiselect__input, .multiselect >>> .multiselect__single {
  background: transparent !important;
  color: #ffffff !important;
}
</style>