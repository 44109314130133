<template>
  <div class="flex">
    <div class="bg-mask"></div>
    <div class="bg-login"></div>
    <div class="m-auto mt-0 w-1/2 z-10 ">
      <img src="../assets/images/logo/logo.png" alt="">
    </div>
    <div class="blur-box" id="phone__check">
      <p class="text-center pt-4 pb-10 font-medium text-white">ثبت نام عضو جدید</p>

      <Step1 v-if="singUpStep === 1" />
      <step2 v-if="singUpStep === 2"/>
      <step3 v-if="singUpStep === 3"/>
      <step4 v-if="singUpStep === 4"/>

      <div class="progress mt-5">
        <span class="active"></span>
        <span :class="singUpStep >= 2 ? 'active' : '' "></span>
        <span :class="singUpStep >= 3 ? 'active' : '' "></span>
        <span :class="singUpStep >= 4 ? 'active' : '' "></span>
      </div>
      <p class="text-white text-xs text-center mt-6">حساب کاربری دارید؟<router-link :to="{name : 'RealEstateLogin'}" class="text-yellow-400 text-base">وارد شوید</router-link></p>
    </div>
  </div>

</template>

<script>
import Step1 from "../components/signup/Step1";
import Step2 from "../components/signup/Step2";
import Step3 from "../components/signup/Step3";
import Step4 from "../components/signup/Step4";
export default {
  name: "signup",
  components: {
    Step1,
    Step2,
    Step3,
    Step4
  },

  data() {
    return {
      phone : '',
      singUpStep : 1
    }
  },
  methods:{

  },
  computed : {
    step(){
      return this.$store.state.auth.singUpStep;
    }
  },
  watch:{
    step(newStep,oldStep){
       this.singUpStep = newStep
    }
  }
}
</script>


<style scoped>
.blur-box{
  top: 6rem;
}

</style>
