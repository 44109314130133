<template>
  <div>
    <ValidationObserver v-slot="{handleSubmit}">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider name="تلفن بنگاه" :rules="{ regex: /([+(\d]{1})(([\d+() -.]){5,16})([+(\d]{1})\d+/ }" v-slot="{ errors }">
          <label class="block relative">
            <span class="text-white opacity-50 text-sm">تلفن بنگاه</span>
            <input
                pattern="\d*"
                type="number"
                v-model="tellPhone"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
            >
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
            <path id="ic_phone_in_talk_24px" d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z" transform="translate(-3 -3)" fill="#ff7400"/>
          </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>

        </ValidationProvider>
        <validation-provider name="نام بنگاه" rules="required|min:3" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">نام بنگاه</span>
            <input
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="realEstateName"
            >
          <span class="absolute top-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="11.586" height="11.73" viewBox="0 0 11.586 11.73">
              <path id="ic_blur_linear_24px" d="M4.287,12.449a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,12.449ZM6.862,9.516a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,9.516Zm0-2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,6.91ZM3,14.73H14.586v-1.3H3ZM4.287,7.236a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,7.236Zm0,2.607a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,9.842Zm2.575,2.281a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,12.123Zm5.15-.326a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,11.8ZM3,3V4.3H14.586V3Zm9.012,3.584a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,6.584Zm0,2.607a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,9.191ZM9.437,6.91a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,6.91Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,9.516Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,12.123Z" transform="translate(-3 -3)" fill="#ff7400"/>
            </svg>
          </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>

        </validation-provider>
        <validation-provider name="کد جواز" rules="required" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">کد جواز</span>
            <input
                v-model="licenseId"
                type="number"
                pattern="\d*"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.119" height="13.836" viewBox="0 0 12.119 13.836">
            <path id="ic_local_library_24px" d="M9.06,8.43A8.856,8.856,0,0,0,3,6.04v7.406a8.856,8.856,0,0,1,6.06,2.39,8.887,8.887,0,0,1,6.06-2.39V6.04A8.856,8.856,0,0,0,9.06,8.43Zm0-2.39A2.02,2.02,0,1,0,7.04,4.02,2.017,2.017,0,0,0,9.06,6.04Z" transform="translate(-3 -2)" fill="#ff7400"/>
          </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>

        </validation-provider>
        <validation-provider name="عکس جواز" rules="ext:jpg,png,jpeg,gif|size:2000" ref="imageInput" v-slot="{ errors, validate }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">عکس جواز</span>
            <div class="file-upload-wrapper text-sm" >
              <span>{{selectFile ? selectFile.name : 'عکس خود را آپلود کنید'}}</span>
              <input
                  type="file"
                  @change="onFileSelected"
                  class="file-upload-filed mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white">
            </div>
            <span class="absolute top-8 right-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="13.13" height="11.988" viewBox="0 0 13.13 11.988">
            <path id="ic_add_a_photo_24px" d="M1.713,2.713V1H2.854V2.713H4.567V3.854H2.854V5.567H1.713V3.854H0V2.713ZM3.425,6.138V4.425H5.138V2.713h4l1.045,1.142h1.81A1.145,1.145,0,0,1,13.13,5v6.85a1.145,1.145,0,0,1-1.142,1.142H2.854a1.145,1.145,0,0,1-1.142-1.142V6.138Zm4,5.138A2.854,2.854,0,1,0,4.567,8.421,2.855,2.855,0,0,0,7.421,11.275ZM5.594,8.421A1.827,1.827,0,1,0,7.421,6.594,1.825,1.825,0,0,0,5.594,8.421Z" transform="translate(0 -1)" fill="#ff7400"/>
          </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>

        </validation-provider>
        <validation-provider name="حوزه فعالیت" rules="required" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 ">حوزه های فعالیت</span>

            <span class="absolute top-8 right-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="11.586" height="11.73" viewBox="0 0 11.586 11.73">
              <path id="ic_blur_linear_24px" d="M4.287,12.449a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,12.449ZM6.862,9.516a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,9.516Zm0-2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,6.91ZM3,14.73H14.586v-1.3H3ZM4.287,7.236a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,7.236Zm0,2.607a.978.978,0,1,0-.966-.977A.97.97,0,0,0,4.287,9.842Zm2.575,2.281a.652.652,0,1,0-.644-.652A.65.65,0,0,0,6.862,12.123Zm5.15-.326a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,11.8ZM3,3V4.3H14.586V3Zm9.012,3.584a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,6.584Zm0,2.607a.326.326,0,1,0-.322-.326A.321.321,0,0,0,12.012,9.191ZM9.437,6.91a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,6.91Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,9.516Zm0,2.607a.652.652,0,1,0-.644-.652A.65.65,0,0,0,9.437,12.123Z" transform="translate(-3 -3)" fill="#ff7400"/>
            </svg>
        </span>
          </label>
          <Multiselect
              v-model="activitySelected"
              :options="activityOptions"
              :multiple="true"
              :taggable="true"
              @tag="addActivity"
              placeholder="حوزه خود را وارد کنید"
              label="translation"
              deselect-label="حذف"
              :closeOnSelect="false"
              tag-placeholder="افزودن"
              select-label="انتخاب کنید"
              selected-label="انتخاب شده"
              open-direction="bottom"
              track-by="code"
          />
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>

        </validation-provider>
        <button type="submit" class="btn-custom mt-11 w-full">بعدی
          <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </button>
      </form>
    </ValidationObserver>
  </div>

</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from "../../plugins/axios";
export default {
  name: "Step2",
  components: {
    Multiselect,
  },
  data(){
    return{
      tellPhone : '',
      realEstateName : '',
      licenseId: '',
      selectFile: null,
      activityOptions : [],
      activitySelected : null,
      imageSrc : null,
      error : null
    }
  },
  methods :{
    addActivity (newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000) )
      }
      this.activityOptions.push(tag)
      this.activitySelected.push(tag)
    },
    async onFileSelected(e){
      const { valid } = await this.$refs.imageInput.validate(e);
      if (valid){
        this.selectFile = e.target.files[0];
        let reader = new FileReader();
        reader.onloadend  = (file) => {
          this.imageSrc = reader.result
        };
        reader.readAsDataURL(this.selectFile);
      }

    },
    onSubmit() {
      let activities = [];
      this.activitySelected.filter((item) => {
        activities.push(item.name)
      })
      const data = {
        tellPhone: this.tellPhone,
        realEstateName: this.realEstateName,
        licenseId: this.licenseId,
        imageSrc: this.imageSrc,
        activitySelected: activities,
        step : 3
      }
      this.$store.commit('auth/SET_SIGNUP_STEP2', data)
    },

  },
   mounted() {
    axios.get('/options/field-of-activity').then(({data})=>{
      this.activityOptions = data.data
      this.activityOptions.map(item =>{
        item['code'] = item.name.substr(0,2) + Math.floor((Math.random() * 10000000) )
      })
    })
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
[dir=rtl] .multiselect >>> .multiselect__tags{
  padding: 11px 19px 7px 40px;
}

</style>