<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <ValidationProvider name="نام" rules="required" v-slot="{ errors }">
          <label class="block relative">
            <span class="text-white opacity-50 text-sm">نام</span>
            <input
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="firstName"
            >
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
            <path id="ic_person_24px"
                  d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                  transform="translate(-4 -4)" fill="#ff7400"/>
            </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>
        </ValidationProvider>
        <ValidationProvider name="نام خانوادگی" rules="required" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">نام خانوادگی</span>
            <input
                type="text"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="lastName"
            >
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.767" height="12.767" viewBox="0 0 12.767 12.767">
            <path id="ic_person_24px"
                  d="M10.384,10.384A3.192,3.192,0,1,0,7.192,7.192,3.191,3.191,0,0,0,10.384,10.384Zm0,1.6C8.253,11.98,4,13.049,4,15.171v1.6H16.767v-1.6C16.767,13.049,12.514,11.98,10.384,11.98Z"
                  transform="translate(-4 -4)" fill="#ff7400"/>
            </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>
        </ValidationProvider>

        <ValidationProvider name="کد ملی" rules="required|digits:10" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">شماره ملی</span>
            <input
                type="number"
                pattern="\d*"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="nationalCode"
            >
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path id="ic_portrait_24px"
                  d="M12,12.25A2.25,2.25,0,1,0,9.75,10,2.253,2.253,0,0,0,12,12.25Zm4.5,4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5,2.25V17h9ZM19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Zm0,16H5V5H19Z"
                  transform="translate(-3 -3)" fill="#ff7400"/>
          </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0] || errorText }}</span>
        </ValidationProvider>
        <!--<ValidationProvider name=" شماره همراه"
                            :rules="{ regex: /(\+98|0|98|0098)?([ ]|-|[()]){0,2}9[0-9]([ ]|-|[()]){0,2}(?:[0-9]([ ]|-|[()]){0,2}){8}/ }" v-slot="{ errors }">
          <label class="block relative mt-3">
            <span class="text-white opacity-50 text-sm">شماره همراه</span>
            <input
                type="number"
                pattern="\d*"
                class="mt-0 block text-white w-full pl-0.5 py-2.5 pr-6 border-0 border-b-2 border-white opacity-50 focus:opacity-100  focus:ring-0 focus:border-white"
                v-model="phone"
            >
            <span class="absolute top-8">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.675" height="12.675" viewBox="0 0 12.675 12.675">
            <path id="ic_phone_in_talk_24px" d="M14.971,11.8a8,8,0,0,1-2.514-.4.719.719,0,0,0-.718.169L10.19,13.119A10.6,10.6,0,0,1,5.549,8.479L7.1,6.922a.676.676,0,0,0,.176-.7,8,8,0,0,1-.4-2.514.706.706,0,0,0-.7-.7H3.7a.706.706,0,0,0-.7.7A11.97,11.97,0,0,0,14.971,15.675a.706.706,0,0,0,.7-.7V12.506A.706.706,0,0,0,14.971,11.8Zm-.7-2.465h1.408A6.338,6.338,0,0,0,9.338,3V4.408A4.926,4.926,0,0,1,14.267,9.338Zm-2.817,0h1.408A3.522,3.522,0,0,0,9.338,5.817V7.225A2.11,2.11,0,0,1,11.45,9.338Z" transform="translate(-3 -3)" fill="#ff7400"/>
          </svg>
        </span>
          </label>
          <span class="text-sm text-red-500 text-right inline-block mt-1">{{errors[0]}}</span>
        </ValidationProvider>-->
        <button type="submit" class="btn-custom mt-11 w-full">بعدی
          <span class="svg-btn-icon bg-orange">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.826" height="20.826" viewBox="0 0 20.826 20.826">
  <path id="ic_arrow_back_24px" d="M24.826,13.111H8.985l7.276-7.276L14.413,4,4,14.413,14.413,24.826l1.835-1.835L8.985,15.715H24.826Z" transform="translate(-4 -4)" fill="#fafafa"/>
</svg>

        </span>
        </button>
      </form>
    </ValidationObserver>
  </div>

</template>

<script>
import axios from "../../plugins/axios";

export default {
  name: "step1",
  data(){
    return{
      phone : '',
      firstName : '',
      lastName : '',
      nationalCode : null,
      errorText : ''
    }
  },
  methods :{
    onSubmit(){
      axios.post('validatation/national-code',{
        value : this.nationalCode
      }).then(({data})=>{
        if (data.status){
          const data = {
            first_name : this.firstName,
            last_name : this.lastName,
            national_code : this.nationalCode,
            step : 2
          }
          this.$store.commit('auth/SET_SIGNUP_STEP1' ,data)
        }else{
          this.errorText = 'کد ملی تکراری می باشد'
          return false;
        }
      }).catch((error)=>{
        this.errorText = error.message
      })
    }
  }
}
</script>

<style scoped>

</style>